.list-group-flush .list-group-item.active {
  border-top: 1px solid rgb(201, 199, 199);
  border-bottom: 1px solid rgb(201, 199, 199);
  padding-top: 0px;
  padding-bottom: 0px;
  color: black;
  background: #fff;
  font-style: italic;
}

.list-group-item {
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: Brandon Text;
  padding-top: 1em;
  padding-bottom: 1em;
}

/**/

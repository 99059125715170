.pagination .page-item.active .page-link {
  color: #fff;
  border-radius: 0.1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
  background-color: #2bbbad;
}

.pagination .page-item .page-link {
  font-size: 14px;
  border: 0;
  outline: 0;
  /* padding-top: 5px; */
}

.page-item.active .page-link {
  z-index: 3;
}

.pagination {
  padding-top: 80px;
  justify-content: center;
}

html,
* {
  box-sizing: border-box;
}

body {
  background-color: rgb (255, 255, 255);
  font-family: Brandon Text;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}
